import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from 'structure/layout'
import SEO from 'structure/seo'
import courses from 'services/courses'

function Promotions ({ data }) {
  return (
    <Layout>
      <SEO
        title='Promoções'
        description='Fique ligado nessa página para aproveitar as promoções dos cursos Ninja!'
        image={process.env.GATSBY_OG_IMAGE_PROMOTIONS || '/queroserninja-promocoes.png'}
        url='/promocoes'
      />

      <Container>
        {Object.entries(courses).map(([course, link]) => (
          <CourseCard
            key={course}
            href={`${link.href}/?couponCode=${link.coupon}`}
            course={course}
            target='_blank'
            rel='noopener noreferrer'
          >
            <p>{link.text} <b>Ninja</b></p>
            <Img fluid={data[course].childImageSharp.fluid} />
            <CardButton course={course}>Quero cupom!</CardButton>
          </CourseCard>
        ))}
      </Container>
    </Layout>
  )
}

const coursesStyle = {
  js: {
    cardBackgroundColor: '#1a1a1a',
    backgroundColor: '#f0db4f',
    color: '#1a1a1a'
  },

  git: {
    cardBackgroundColor: '#800000',
    backgroundColor: '#e74c3c'
  },

  react: {
    cardBackgroundColor: '#1a1a1a',
    backgroundColor: '#3498db'
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media all and (min-width: 768px) {
    & {
      flex-direction: row;
    }
  }
`

const CourseCard = styled.a`
  background: ${({ course }) => coursesStyle[course].cardBackgroundColor};
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 13px;
  margin: 5px;
  text-decoration: none;
  text-transform: uppercase;

  p {
    margin: 0;
    padding: 5px;
  }
`

const CardButton = styled.strong`
  background-color: ${({ course }) => coursesStyle[course].backgroundColor};
  color: ${({ course }) => coursesStyle[course].color || '#fff'};
  font-size: 18px;
  padding: 12px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    js: file(relativePath: { eq: "curso-javascript-ninja.png" }) {
      ...fluidImage
    }

    git: file(relativePath: { eq: "curso-git-e-github-ninja.png" }) {
      ...fluidImage
    }

    react: file(relativePath: { eq: "curso-react-ninja.png" }) {
      ...fluidImage
    }
  }
`

export default Promotions
