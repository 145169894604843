import javascript from 'images/curso-javascript-ninja.png'
import gitGitHub from 'images/curso-git-e-github-ninja.png'
import react from 'images/curso-react-ninja.png'

const courses = {
  js: {
    href: 'https://www.udemy.com/curso-javascript-ninja',
    text: 'JavaScript',
    image: javascript,
    coupon: process.env.GATSBY_JS_COUPON || 'AGORAEUVIRONINJA' // JSNINJA10
  },

  git: {
    href: 'https://www.udemy.com/git-e-github-ninja',
    text: 'Git e GitHub',
    image: gitGitHub,
    coupon: process.env.GATSBY_GIT_COUPON || 'QUEROGIT'
  },

  react: {
    href: 'https://www.udemy.com/curso-reactjs-ninja',
    text: 'React',
    image: react,
    coupon: process.env.GATSBY_REACT_COUPON || 'PREVENDA'
  }
}

const mainCoupon = process.env.GATSBY_MAIN_COUPON

export default Object.entries(courses).reduce((acc, [key, value]) => ({
  ...acc,
  [key]: {
    ...value,
    coupon: mainCoupon || value.coupon
  }
}), {})
